<template>
  <main>
    <!-- Modal -->
    <div class="modal fade" id="modalEmbarazo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Embarazo</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="form-labelñ">Fecha</label>
              <input type="date" class="form-control" v-model="fecha">
            </div>
            <antecedenteObstetrico ref="antecedenteObstetrico"></antecedenteObstetrico>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="guardar();">Guardar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header">
      <div class="row">
        <div class="col-6 ">
          Embarazo
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button v-if="ultimo_embarazo.activo !== 'S'" class="btn btn-primary btn-xs" type="button" data-toggle="modal" data-target="#modalEmbarazo">
            Nuevo &nbsp;<i class="fas fa-plus"></i>
          </button>
          <button v-else class="btn btn-warning btn-xs" type="button" @click="finalizar">Finalizar</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <span class="d-block small">Ultimo Embarazo :
        <span v-if="Object.keys(ultimo_embarazo).length">{{ultimo_embarazo.fecha}}</span>
        <span v-else class="text-danger">Sin datos...</span>
      </span>
    </div>
  </main>
</template>
<script>

import embarazoService from '../../../../services/embarazoService';
import antecedenteObstetrico from "./antecedenteObstetrico";
import $ from 'jquery';
import Swal from "sweetalert2";

export default {
  props: ['idUsuario', 'usuario'],
  components:{antecedenteObstetrico},
  data(){
    return {
      embarazo:{},
      fecha: '',
      ultimo_embarazo:{},
      id_usuario: '',
    }
  },
  methods: {
    async cargarEmbarazo(){
      const response = embarazoService.showById(this.idUsuario);
      this.embarazo= response.data;
    },
    async cargarUltimoEmbarazo(id){
      const response = await embarazoService.showByLast(id);
      this.ultimo_embarazo = response.data;
    },
    async guardar() {
      try {
        let antecedente_ob=[];
        antecedente_ob = this.$refs.antecedenteObstetrico.retornar();

        let embarazo={
          fecha: this.fecha,
          id_usuario: this.idUsuario,
          antecedentes:antecedente_ob
        };

        this.LoaderSpinnerShow();
        await embarazoService.store(embarazo);
        this.LoaderSpinnerHide();
        $('.modal-backdrop').remove();
        $("#modalEmbarazo").hide();
        this.cargarUltimoEmbarazo(this.idUsuario);
        Swal.fire('Datos guardados con exito','', 'success');
      } catch (error) {
        console.error(error);
        this.LoaderSpinnerHide();
        Swal.fire('Ha ocurrido un error al procesar la solicitud','', 'error');
      }
      

    },
    async finalizar(){

      const result = await Swal.fire({
        title :  "¿Está seguro de finalizar el embarazo ?",
        text : "Al finalizar el embarazo no se podra continuar el control prenatal",
        showCancelButton : true,
        showConfirmButton : true,
        confirmButtonText : 'Sí, finalizar',
        cancelButtonText : 'No',
        icon : "warning",
        showLoaderOnConfirm : true,
        preConfirm: async () => {
          try{
            await embarazoService.close(this.ultimo_embarazo.id);
          }catch (e) {
            console.error(e);
            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
          }

        },
      });

      if(result.isConfirmed){
        Swal.fire('Exíto', 'Embarazo Finalizado con Exíto', 'success');
        this.cargarUltimoEmbarazo(this.idUsuario);
      }
    }
  },
  created() {
    this.cargarUltimoEmbarazo(this.idUsuario);
  },
  watch: {
    idUsuario:function(){
      this.cargarUltimoEmbarazo(this.idUsuario);
    }
  },
}
</script>