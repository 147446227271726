import Service from './Service';

const resource = '/api/citas';

export default {
    atendida(id){
        return Service.put(`${resource}/atendida/${id}`);
    },
    last(id_usuario){
        return Service.get(`${resource}/last/${id_usuario}`);
    }
}