<template>
  <main>
    <div class="text-left ">
      <h6><i class="fa fa-check text-primary"></i> {{antecedente_usuario.antecedente.descripcion}}</h6>
      <span>{{antecedente_usuario.valor}}</span>
    </div>
  </main>
</template>
<script>
export default {
    props: ['antecedente_usuario']
}
</script>
