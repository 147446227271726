<template>
    <main>
        <div class="modal fade" id="modalPerfil" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-list fa-fw text-primary"></i> Tipos Historias Clinicas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row text-center ">
                    <button v-for="tipo in tipoHistoria" :key="tipo.id" class="btn btn-outline-light shadow-sm lift mx-2 py-2 my-2 text-dark" @click="nuevaHistoria(tipo.id)">
                        {{tipo.nombre}}
                    </button>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
            </div>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import historiaClinicaService from '../../../services/historiaClinicaService';
import tipoHistoriaService from '../../../services/tipoHistoriaService';
import citasService from '../../../services/citasService';
import $ from 'jquery';
import 'bootstrap';
import {isEmpty} from 'lodash';

export default {
    props:['usuario'],
    data () {
        return {
            
            historia : {
                id : '',
                fecha : '',
                id_usuario : '',
                id_tipo_historia:'',
            },
            tipoHistoria : [],
        }
    },
    methods : {
        async nuevaHistoria(id){
            try{
                
                var hoy = new Date();
                
                var fechaAct = hoy.getFullYear() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getDate();
                
                this.historia.id_usuario = this.usuario.id;
                this.historia.id_tipo_historia = id;
                this.historia.fecha=fechaAct;

                const response = await historiaClinicaService.store(this.historia);
                
                $('#modalPerfil').modal('hide');

                const tipoHistoria = this.tipoHistoria.find(x => x.id === id)

                this.$router.push({
                    name : tipoHistoria.path,
                    params : { 
                        id : response.data.id
                    }
                });

                if(!isEmpty(response.data)){
                    const response_last_cita=await citasService.last(this.usuario.id);
                    if(!isEmpty(response_last_cita.data)){
                        await citasService.atendida(response_last_cita.data.id);
                    }
                    console.log(response_last_cita.data);
                }

            }catch(err){
                console.error(err);
            }
        },
        async cargarTipoHistoria(){
            const response= await tipoHistoriaService.index({
              fecha_nacimiento :  this.usuario.fecha_nacimiento,
              sexo : this.usuario.sexo,
            });
            this.tipoHistoria = response.data;
        },
    },
}
</script>

