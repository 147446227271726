<template>
  <main>
    <div class="card-header">
      Citologías
    </div>
    <div class="card-body">
      <div v-if="Object.keys(muestras).length">
        <span v-for="(muestra, i) in muestras" :key="`muestra_${i}`" class="d-block small">
          Fecha : {{ muestra.fecha_lectura }} 
          <router-link v-if="muestras.length" :to="{
            name : 'historias.citologia.resultado.create',
            params : {
              id : muestra.id
            }
          }" class="btn btn-primary btn-xs mx-2" type="button">
              Resultados <i class="fas fa-plus fa-fw"></i>
          </router-link>
        </span>
      </div>
      <div v-else class="alert alert-danger" role="alert">Sin datos...</div>
    </div>
  </main>
</template>
<script>

import tomaMuestrasService from "@/services/tomaMuestrasService";

export default {
  props: ['idUsuario'],
  data() {
    return {
      muestras: [],
    }
  },
  methods: {
    async cargar() {
      let response = await tomaMuestrasService.index({
        id_usuario: this.idUsuario,
        without_results: true
      });
      this.muestras = response.data;
    }
  },
  watch: {
    idUsuario: function () {
      this.cargar();
    }
  },
  created() {
    this.cargar();
  }
}
</script>