<template>
    <main>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">GESTAS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.gestas">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">PARTOS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.partos">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">CESAREAS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.cesareas">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">ABORTOS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.abortos">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">VAGINALES</label>
                <input type="number" class="form-control" v-model="antecedente_ob.vaginales">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">MAS DE 3 PARTOS ESPONTANEOS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.partos_espontaneos">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">NACIDOS VIVOS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.nacidos_vivos">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">VIVEN</label>
                <input type="number" class="form-control" v-model="antecedente_ob.viven">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">NACIDOS MUERTOS</label>
                <input type="number" class="form-control" v-model="antecedente_ob.nacidos_muertos">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">MUERTOS 1 SEM</label>
                <input type="number" class="form-control" v-model="antecedente_ob.muertos">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">RN MENOR DE 2500 GR</label>
                <select v-model="antecedente_ob.rn_menor" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">RN MAYOR PESO GR</label>
                <input type="number" class="form-control" v-model="antecedente_ob.rn_mayor">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">GEMELARES</label>
                <input type="number" class="form-control" v-model="antecedente_ob.gemelares">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">FECHA ANT EMBARAZO</label>
                <input type="date" class="form-control" v-model="antecedente_ob.fecha_anterior">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">MUERTOS DESP 1 SEM</label>
                <input type="number" class="form-control" v-model="antecedente_ob.muertos_despues">
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">RPM</label>
                <select v-model="antecedente_ob.rpm" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">RCIU</label>
                <select v-model="antecedente_ob.rciu" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">AMENAZA PP</label>
                <select v-model="antecedente_ob.amenaza_pp" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">MALFORMACION</label>
                <select v-model="antecedente_ob.malformacion" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">OLIGOHIDRAMNIOS</label>
                <select v-model="antecedente_ob.oligohidramnios" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">EMB. PROLONGADO</label>
                <select v-model="antecedente_ob.embarazo_prolongado" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">AMENAZA ABORTO</label>
                <select v-model="antecedente_ob.amenaza_aborto" class="form-control">
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    data(){
        return {
            antecedente_ob:{
                gestas:0,
                partos:0,
                cesareas:0,
                abortos:0,
                vaginales:0,
                partos_espontaneos:0,
                nacidos_vivos:0,
                viven:0,
                nacidos_muertos:0,
                muertos:0,
                rn_menor:'',
                rn_mayor:0,
                gemelares:0,
                fecha_anterior:'',
                muertos_despues:0,
                rpm:'',
                rciu:'',
                amenaza_pp:'',
                malformacion:'',
                oligohidramnios:'',
                embarazo_prolongado:'',
                amenaza_aborto:'',
            }
        }
    },
    methods: {
        retornar(){
            return this.antecedente_ob;
        }
    },
}
</script>