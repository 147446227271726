<template>
  <main>
    <div class="card mb-4">
      <div class="card-header">
        <div class="row">
          <div class="col-6 ">
            Antecedentes
          </div>
          <div class="col-6 d-flex justify-content-end">
            <router-link
              :to="{ name: 'usuario.antecedentes', params: { id: idUsuario } }"
              class="btn btn-primary btn-xs"
            >
              Diligenciar <i class="fas fa-plus fa-fw"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-for="grupo in pagination.data" :key="grupo.id">
          <grupo :grupo="grupo" :idUsuario="idUsuario"></grupo>
        </div>
      </div>
      <div class="card-footer">
          <pagination-links
                @changePage="changePage($event)"
                @prevPage="changePage($event)"
                @nextPage="changePage($event)"
                :per-page="pagination.per_page"
                :total="pagination.total"
                :current-page="pagination.current_page">
        </pagination-links>
      </div>
    </div>
  </main>
</template>
<script>
import grupoAntecedenteService from '../../../services/grupoAntecedenteService';
import grupo from "../antecedente/show/grupo";
import paginateMixin from "../../../mixins/PaginateMixin";
import paginationLinks from "../../../components/common/utilities/PaginationLinks";


export default {
    mixins : [
        paginateMixin(
            async (search, pagination) => grupoAntecedenteService.index(pagination),
            false
        )
    ],
    props: ['idUsuario'],
    components:{grupo,paginationLinks},
    data(){
        return {
            pagination:{
                per_page:2,
               
            }
        }
    },
    watch : {
        idUsuario:function(){
            this.index();
        }
    },
    methods: {
        changePage(page){
            this.pagination.page = page;
        },
    },
    
}
</script>

