<template>
  <main>
    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-5">
      <div class="container-xl px-4">
        <div class="page-header-content pt-4">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto mt-4">
              <h2 class="page-header-title">
                Historia Clinica - Perfil de Usuario
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
    <br>
    <div class="container-xl px-4 mt-n10">
      <div class="row" v-if="historia.finalizada==='N'">
        <div class="col-12">
          <div class="alert alert-warning">
            <i class="fa fa-exclamation-triangle fa-fw"></i> Este usuario tiene una historia sin finalizar, favor finalizar para crear una nueva.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <div class="card card-icon">
            <div class="row no-gutters">
              <div class="col-auto card-aside bg-gray-200 " >
                <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
                <div class="text-center" v-if="usuario.sexo === 'F'">
                  <img class="py-2" src="@/assets/img-genero/mujer.png" width="96px">
                  <h6><button class="btn btn-outline-dark btn-icon btn-sm" data-toggle="modal" data-target="#perfilUsuario" ><i class="fa fa-eye"></i></button></h6>
                </div>
                <div class="text-center" v-if="usuario.sexo === 'M'">
                  <img class="py-2" src="@/assets/img-genero/hombre.png" width="96">
                  <h6><button class="btn btn-outline-dark btn-icon btn-sm" data-toggle="modal" data-target="#perfilUsuario" ><i class="fa fa-eye"></i></button></h6>
                </div>
              </div>
              <div class="col">
                <div class="card-body">
                  <h5 class="card-title">{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</h5>
                  <p class="card-text">{{usuario.fecha_nacimiento}} | {{usuario.documento}} </p>
                  <span class="badge badge-success p-1 shadow-sm mx-2" v-if="usuario.activo==='S'">Activo</span>
                  <router-link
                  :to="{ name: 'usuario.historial.historias', params: { id: usuario.id } }"
                    class="btn btn-info btn-xs shadow-sm "
                  >
                     Historial  <i class="fas fa-history fa-fw"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="card mb-4 " >
            <!-- apgar familiar card-->
            <cardApgarFamiliar :idUsuario="usuario.id" :usuario="usuario"></cardApgarFamiliar>            
          </div>
          <div class="card mb-4" v-if="usuario.sexo === 'F'">
            <!-- embarazo card-->
            <cardEmbarazo :idUsuario="usuario.id" :usuario="usuario"></cardEmbarazo>
          </div>
        </div>
        <div class="col-xl-5">
          <!-- Antecedentes card-->
          <cardAntecedente :idUsuario="usuario.id"></cardAntecedente>
          <div class="card mb-4" v-if="usuario.sexo === 'F'">
            <!-- citologias card-->
            <card-citologias :id-usuario="usuario.id"></card-citologias>
          </div>
        </div>
        <div class="col-xl-3">
          <!-- botones card-->
          <div class="card mb-4">
            <div class="card-body text-center" v-if="Object.entries(triage).length && historia.finalizada!=='N'">
              <button  class="btn btn-primary  btn-lg" type="button" @click="historiaUrgencia();">
                Llenar Historia Urgencia &nbsp;<i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="card-body text-center"  v-else-if="historia.finalizada!=='N'">
              <button  class="btn btn-primary  btn-lg" type="button" data-toggle="modal" data-target="#modalPerfil">
                Llenar Historia &nbsp;<i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="card-body" v-else-if="historia.finalizada==='N'" >
              <div class="row" v-if="historia.tipo_historia.codigo==='URG'" >
                <div class="col-12 " >
                  <remision-component :idHistoria="historia.id"></remision-component>
                </div>
                <div class="col-12 mt-2">
                  <finalizar-historia :idHistoria="historia.id" :idUsuario="usuario.id"></finalizar-historia>
                </div>
              </div>
              <div v-else>
                <button @click="continuarHistoria();" class="btn btn-warning  btn-lg" type="button"  >
                  Continuar Historia &nbsp; <i class="fas fa-check"></i>
                </button>
              </div>
            </div>
          </div>
          <modalPerfil ref="modalPerfil" :usuario="usuario"></modalPerfil>
          <div v-if="historia.tipo_historia">
            <div class="card" v-if="historia.tipo_historia.codigo==='URG'">
              <div class="card-body text-center" >
                <button @click="ordenamiento();" class="btn btn-warning  btn-lg" type="button"  >
                  Ordenamiento  <i class="far fa-list-alt fa-fw"></i>
                </button>
              </div>
            </div>
            <div class="card mt-4" v-if="historia.tipo_historia.codigo==='URG'">
              <div class="card-body text-center" >
                <button @click="formulas();" class="btn btn-info  btn-lg" type="button">
                  Medicamentos <i class="fas fa-capsules fa-fw"></i>
                </button>
              </div>
            </div>
            <div class="card mt-4" v-if="historia.tipo_historia.codigo==='URG'">
              <div class="card-body text-center" >
                <button @click="ordenes();" class="btn btn-info  btn-lg" type="button"  >
                  Procedimientos <i class="fas fa-clipboard-list fa-fw"></i>
                </button>
              </div>
            </div>
            <div class="card mt-4" v-if="historia.tipo_historia.codigo==='URG'">
              <div class="card-body text-center" >
                <button @click="evoluciones();" class="btn btn-success  btn-lg" type="button"  >
                  Evoluciones <i class="fas fa-clipboard-check fa-fw"></i>
                </button>
              </div>
            </div>
          </div>
           <div class="card mt-4" >
              <!-- notas enfremeria card-->
              <cardNotasEnfermeria :idUsuario="id_usuario" :usuario="usuario"></cardNotasEnfermeria>
          </div>
          
          
        </div>
      </div>
    </div>
  </main>

</template>

<script>

import usuarioService from '../../../services/usuarioService';
import historiaClinicaService from '../../../services/historiaClinicaService';
import {isEmpty} from 'lodash';
import modalPerfil from './modalPerfil';
import cardAntecedente from "./cardAntecedente";
import cardApgarFamiliar from "../base/baseApgarFamiliar/cardApgarFamiliar";
import cardEmbarazo from "../base/baseEmbarazo/cardEmbarazo";
import cardNotasEnfermeria from "../base/baseNotasEnfermeria/cardNotasEnfermeria";
import urgenciaTriageService from '../../../services/urgenciaTriageService';
import urgenciaTriageHistoriaService from '../../../services/urgenciaTriageHistoriaService';
import tipoHistoriaService from '../../../services/tipoHistoriaService';
import CardCitologias from "@/views/historias/citologia/cardCitologias";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import FinalizarHistoria from '../base/finalizarHistoria/finalizarHistoria';
import RemisionComponent from "../base/baseRemision/index";
export default {
  components: {
    CardCitologias,
    modalPerfil,cardAntecedente,cardApgarFamiliar,cardEmbarazo,cardNotasEnfermeria,modalInformacionUsuario,
    FinalizarHistoria, RemisionComponent},
  data () {
    return {

      historia : {
        id : '',
        fecha : '',
        id_usuario : '',
        finalizada:''
      },
      usuario : {},
      triage:{},
      id_usuario:''

    }
  },
  methods : {
    ordenes(){
      this.$router.push({
        name: 'ordenes.usuario', params: { id_historia:this.historia.id }
      });
    },
    formulas(){
      this.$router.push({
        name: 'formulas.usuario', params: { id_historia:this.historia.id }
      });
    },
    ordenamiento(){
      this.$router.push({
        name: 'ordenamiento', params: { id:this.historia.id }
      });
    },
    evoluciones(){
      this.$router.push({
        name: 'evoluciones.usuario', params: { id_historia:this.historia.id }
      });
    },
    async cargarUsuario(){
      const response= await usuarioService.show(this.id_usuario);
      this.usuario=response.data;
      
    },
    continuarHistoria(){
      this.$router.push({
        name: this.historia.tipo_historia.path, params: { id:this.historia.id }
      });
    },
    async consultarTriage(){
      const response = await urgenciaTriageService.showByIdUser(this.id_usuario);
      this.triage=response.data;
    },
    async historiaUrgencia(){
      try{

        let hoy = new Date();

        let fechaAct = hoy.getFullYear() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getDate();

        this.historia.fecha=fechaAct;

        const response_tipo_historia= await tipoHistoriaService.index();

        const tipoHistoria = response_tipo_historia.data.find(x => x.codigo === 'URG');

        const historia= {
          fecha:fechaAct,
          id_tipo_historia:tipoHistoria.id,
          id_usuario:this.usuario.id

        };

        const response = await historiaClinicaService.store(historia);

        const historia_triage={
          id_historia:response.data.id,
          id_triage:this.triage.id
        }

        await urgenciaTriageHistoriaService.store(historia_triage);

        this.$router.push({
          name : tipoHistoria.path,
          params : {
            id : response.data.id
          }
        });

      }catch(err){
        console.error(err);
      }
    },
    async historiaFinalizada(){
      //this.LoaderSpinnerShow();
      const response=await historiaClinicaService.showUnfinished(this.id_usuario);
      //this.LoaderSpinnerHide();
      if(!isEmpty(response.data)){
        this.historia= response.data;
        
      }

    }

  },
  async created(){

    this.id_usuario = this.$route.params.idUsuario;

    

    this.cargarUsuario();

    this.historiaFinalizada();

    this.consultarTriage();

    this.$nextTick(() => this.$refs.modalPerfil.cargarTipoHistoria());

    
  },

}
</script>