<template>

    <main>
        <h5 class="text-info">{{grupo.nombre}}</h5>
        <antecedente v-for="antecedente in pagination.data" :key="antecedente.id" :antecedente_usuario="antecedente" ></antecedente>
        <pagination-links
                @changePage="changePage($event)"
                @prevPage="changePage($event)"
                @nextPage="changePage($event)"
                :per-page="pagination.per_page"
                :total="pagination.total"
                :current-page="pagination.current_page">
        </pagination-links>
        <hr> 
    </main>
</template>
<script>
import antecedenteUsuarioService from '../../../../services/antecedenteUsuarioService';
import antecedente from "./antecedente";
import paginateMixin from "../../../../mixins/PaginateMixin";
import paginationLinks from "../../../../components/common/utilities/PaginationLinks";
export default {
    mixins : [
        paginateMixin(
            async (search, pagination,id,id_grupo) => antecedenteUsuarioService.index(id,id_grupo,pagination),
            false
        )
    ],
    props: ['grupo','idUsuario'],
    components : {antecedente,paginationLinks},
    data(){
        return {
            pagination:{
                per_page:5,
                params:{
                    id:this.idUsuario,
                    id_grupo:this.grupo.id,
                }
            }
        }
    },
    methods: {
        changePage(page){
            this.pagination.page = page;
        },
    },
    created(){
        this.index();
        
    }
}
</script>

